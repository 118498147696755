<template>
  <report filename="Subdiarios" report="sub_daily">
    <template #filters="{ apply, loading, setColumns, filterKey }">
      <report-filters
        :filter-key="filterKey"
        :loading="loading"
        :movement-type-code="[1, 8]"
        :selected-columns.sync="arSelectedHeaders"
        currency-convert-empty-option
        @apply="apply"
        @columns="setColumns"
        @reset="onSetInitFilterData"
      />
    </template>
  </report>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { DataTableHeader } from "@/mixins/DataTableMixin";

import Report from "@/modules/reports/components/Report.vue";
import ReportFilters from "@/modules/reports/components/ReportFilters.vue";
import { AppModule } from "@/store/app";
import ReportFilterMixin from "@/modules/reports/mixins/ReportFilterMixin";

@Component({
  components: {
    ReportFilters,
    Report,
  },
})
export default class SubDailyReport extends Mixins(ReportFilterMixin) {
  arSelectedHeaders: DataTableHeader["value"][] = [
    "created_at",
    "invoice_type.short_name",
    "order_number",
    "customer_id",
    "customer.firm.doc_formatted",
    "currency",
    "currency_convert",
    "rate",
    "taxes",
    "total_price_value",
  ];

  get obCurrencies() {
    return AppModule.currencies;
  }

  get obDefaultCurrency() {
    return this.obCurrencies.find({ is_default: true });
  }

  onSetInitFilterData() {
    this.setFilter("movementTypeCode", 1);
    this.setFilter("currency", 0);
    this.setFilter("signed", 1);

    if (this.obDefaultCurrency && !this.obFilterData.currency_convert) {
      this.setFilter("currency_convert", this.obDefaultCurrency.id);
    }
  }

  created() {
    this.setDefaultHeaders();
  }
}
</script>
